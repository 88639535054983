<template>
  <base-layout class="min-h-screen pb-2 page-gradient">
    <section>
      <content-section class="mt-2 md:px-12 md:pb-10">
        <div>
          <div
            class="flex flex-col items-center justify-between my-5 md:flex-row"
          >
            <div class="section-title">
              Trip Locations
              <span v-if="map_load_text !== ''" class="section-title-faded">{{
                map_load_text
              }}</span>
            </div>
          </div>
          <LiveMapFilterPills
            ref="heatmap_filter"
            @filterHeatmapData="filterData()"
            :count="count"
            :fleetData="fleetData"
          />
        </div>
        <template>
          <div
            v-show="chartLoaded"
            class="grid grid-cols-1 md:gap-0 md:grid-cols-1"
          >
            <div style="min-height: 420px">
              <gmap-map
                ref="map"
                :zoom="map_zoom"
                :center="map_center"
                map-type-id="terrain"
                style="width: 100%; height: 70vh"
              >
              </gmap-map>
            </div>
            <div v-if="showSlider && sliderDates.length > 1">
              <HistogramSlider
                ref="hist_slider"
                style="margin: 50px auto; width: 90%"
                :bar-height="0"
                :data="sliderDates"
                :prettify="prettifySlider"
                :drag-interval="true"
                :force-edges="false"
                :colors="['#4facfe', '#00f2fe']"
                :min="minSliderDate"
                :max="maxSliderDate"
                @finish="onDateSliderChange"
              />
            </div>
          </div>
        </template>
        <TheSuspense class="p-4 bg-white" v-if="!chartLoaded">
          <SuspenseImg :height="`646px`" />
        </TheSuspense>
      </content-section>
    </section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import LiveMapFilterPills from './LiveMapFilterPills'
import ContentSection from '@/components/layout/ContentSection'
import { AnalyticsConfig } from '@/config/AnalyticsConfig'
import { getDateFromOneToOther } from '@/utils/ConvertDate'
import { gmapApi } from 'vue2-google-maps'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { httpClient } from '@/services'

export default {
  name: 'DemandHeatmap',
  components: {
    BaseLayout,
    ContentSection,
    LiveMapFilterPills,
  },
  data() {
    return {
      // isLoaded: false,
      count: {
        pick_up: 0,
        drop_off: 0,
        fleets: {},
      },
      map: null,
      map_zoom: 10,
      map_center: { lat: 23.810331, lng: 90.412521 }, //{ lat: 42.3726399, lng: -71.1096528 },
      map_summary: {},
      raw_data: [],
      filtered_data: [],
      map_data: [],
      raw_data_count: 0,
      map_mode: 'only_src',
      start_date: '',
      end_date: '',
      chartLoaded: true,
      bikeLayer: null,
      heatmapLayer: null,
      clusterLayer: null,
      gradient: [
        'rgba(255, 0, 0, 0)',
        'rgba(255, 0, 0, 0.1)',
        'rgba(255, 0, 0, 0.2)',
        'rgba(255, 0, 0, 0.3)',
        'rgba(255, 0, 0, 0.4)',
        'rgba(255, 0, 0, 0.5)',
        'rgba(255, 0, 0, 0.6)',
        'rgba(255, 0, 0, 0.7)',
        'rgba(255, 0, 0, 0.8)',
        'rgba(255, 0, 0, 0.9)',
        'rgba(255, 0, 0, 1)',
      ],
      styles: [
        {
          featureType: 'administrative.locality',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'administrative.neighborhood',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'landscape',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'transit',
          elementType: 'all',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'water',
          elementType: 'labels',
          stylers: [{ visibility: 'on' }],
        },
      ],
      limit: 1000,
      map_load_text: 'Requesting Data...',
      fleetData: [],
      minSliderDate: new Date().valueOf(),
      maxSliderDate: new Date().valueOf(),
      // sliderDates: [],
      showSlider: false,
    }
  },
  async mounted() {
    this.fleetData = await this.getFleetDropdownData()
    this.showSlider = false
    await this.initDraw()

    // this.google.maps.event.addDomListener(window, 'load', this.initDraw)
  },
  computed: {
    google: gmapApi,
    mapFilters() {
      return this.$refs.heatmap_filter.definedFilters()
    },
    dateFilter() {
      return this.$refs.heatmap_filter.filter_date_range
    },
    // minSliderDate() {
    //   if (this.getSortedFilteredDataByDate().length == 0) return new Date().valueOf()
    //   return new Date(this.getSortedFilteredDataByDate()[0].created_at).valueOf()
    // },
    // maxSliderDate() {
    //   let sortedData = this.getSortedFilteredDataByDate()
    //   if (sortedData.length <= 1) return new Date().valueOf()
    //   return new Date(sortedData[sortedData.length - 1].created_at).valueOf()
    // },
    sliderDates() {
      return getDateFromOneToOther(this.minSliderDate, this.maxSliderDate).map(
        (data) => new Date(data)
      )
    },
  },
  watch: {
    filtered_data() {
      let sortedData = this.getSortedFilteredDataByDate()
      this.minSliderDate =
        sortedData.length > 0
          ? new Date(sortedData[0].created_at).valueOf()
          : new Date().valueOf()
      this.maxSliderDate =
        sortedData.length > 1
          ? new Date(sortedData[sortedData.length - 1].created_at).valueOf()
          : new Date().valueOf()
    },
  },
  methods: {
    getSortedFilteredDataByDate() {
      return [...this.filtered_data].sort((a, b) => {
        return (
          new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf()
        )
      })
    },
    onDateSliderChange(e) {
      console.log('Slider ranged', e)
      let data = this.getDataFilteredByDateSlider(
        this.filtered_data,
        e.from,
        e.to
      )
      // if (data.length == 0) {
      //   this.minSliderDate = new Date(this.raw_data[0].created_at).valueOf()
      //   this.maxSliderDate = new Date(this.raw_data[this.raw_data.length - 1].created_at).valueOf()
      //   this.plotFilteredData()
      //   return
      // }
      this.filtered_data = data
      this.plotFilteredData()
    },
    prettifySlider(ts) {
      let minDate = new Date(this.minSliderDate)
      let maxDate = new Date(this.maxSliderDate)
      if (
        minDate.year === maxDate.year &&
        minDate.month === maxDate.month &&
        minDate.day === maxDate.day
      ) {
        return new Date(ts).toLocaleDateString('en', {
          hour: 'numeric',
          minute: 'numeric',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      } else {
        return new Date(ts).toLocaleDateString('en', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }
    },
    getSanitizedCoord(coord) {
      return parseFloat(coord.trim())
    },
    populateFleetCounts() {
      this.fleetData.forEach((fleet) => {
        this.count.fleets[fleet.id] = this.filtered_data.filter((data) => {
          return data.fleet_id == fleet.id
        }).length
      })
    },
    async getFleetDropdownData() {
      const fleetsUrl = `/dashboard/fleets/?dropdown=true`
      try {
        const fleetsRes = await httpClient.get(fleetsUrl)
        console.log('Loaded Fleets DR Data', fleetsRes.data)

        let parsedArr = fleetsRes.data.data
        // console.log(parsedArr)
        return Promise.resolve(parsedArr)
      } catch (err) {
        console.error(err)
        // todo: create setError() mutation
        // commit('setError', err)
        return Promise.reject(err)
      }
    },
    getLocationFromCoordString(coord_str) {
      let arr = coord_str.split(',')
      return new this.google.maps.LatLng(
        this.getSanitizedCoord(arr[0]),
        this.getSanitizedCoord(arr[1])
      )
      // return new this.google.maps.visualization.WeightedLocation(location, 0.5)
    },
    getDataFilteredByDateRange(data) {
      return data.filter((entry) => {
        return (
          Date.parse(this.dateFilter.start) <= Date.parse(entry.created_at) &&
          Date.parse(entry.created_at) <= Date.parse(this.dateFilter.end)
        )
      })
    },
    getDataFilteredByDateSlider(data, min, max) {
      console.log(
        data.length,
        'HGAFCZBZHJSTYDXGCFBNJXGUYDRGSFCXBNVJGSUFHTXZCGVNJSGUFHTG'
      )
      return data.filter((entry) => {
        return (
          new Date(min) <= Date.parse(entry.created_at) &&
          Date.parse(entry.created_at) <= new Date(max)
        )
      })
    },
    getDataFilteredBySelectedFleets(data) {
      let selectedFleets = []
      for (const k in this.mapFilters.filterFleet.models) {
        if (this.mapFilters.filterFleet.models[k] === true) {
          selectedFleets.push(k)
        }
      }
      return data.filter((entry) => {
        return selectedFleets.includes(entry.fleet_id)
      })
    },
    plotFilteredData() {
      // console.log("===========================")
      // console.log("watching filtered_data here")
      // console.log("===========================")
      this.map_data = this.getProcessedMapData(this.filtered_data)
      console.log(this.map_data.length)
      this.populateFleetCounts()
      this.resetBoundForHeatMapData()
      this.addHeatMapLayer()
      this.addClusterLayer()
    },
    filterData() {
      this.filtered_data = this.getDataFilteredByDateRange(this.raw_data)
      if (this.mapFilters.filterFleet.isApplied)
        this.filtered_data = this.getDataFilteredBySelectedFleets(
          this.filtered_data
        )
      console.log('Date filtered count', this.filtered_data.length)
      console.log(this.filtered_data.length)
      this.plotFilteredData()

      this.showSlider = false
      this.$nextTick(() => {
        this.showSlider = true
      })
    },
    getProcessedMapData(data_arr) {
      var proc_data = []
      this.count.pick_up = 0
      this.count.drop_off = 0

      for (var data of data_arr) {
        if (data.src_coord) this.count.pick_up += 1
        if (data.dest_coord) this.count.drop_off += 1

        if (this.mapFilters.filterLocationPoint.isApplied) {
          if (data.src_coord) {
            if (this.mapFilters.filterLocationPoint.models.pickup_points)
              proc_data.push(this.getLocationFromCoordString(data.src_coord))
          }
          if (data.dest_coord) {
            if (this.mapFilters.filterLocationPoint.models.dropoff_points)
              proc_data.push(this.getLocationFromCoordString(data.dest_coord))
          }
        } else {
          if (data.src_coord)
            proc_data.push(this.getLocationFromCoordString(data.src_coord))
        }
      }
      this.map_load_text = `Filtered ${proc_data.length} trips`
      return proc_data
    },
    async getTripMapData(reInit = false) {
      if (reInit) {
        this.raw_data = []
        this.map_data = []
      }
      console.log('Requesting map Data...')
      await this.$http
        .get(
          `${AnalyticsConfig.api.trip_heatmap}?mode=${this.map_mode}&limit=${this.limit}&offset=${this.raw_data.length}`
        )
        .then(async (res) => {
          this.map_summary = res.data.meta.summary
          this.raw_data_count = res.data.meta.count
            ? res.data.meta.count.total
            : 0
          if (reInit) {
            let leftover = this.raw_data_count - this.limit
            this.limit =
              leftover > this.limit ? Number.parseInt(leftover / 2) : leftover
            console.log('resetted limit to ' + this.limit)
          }
          // console.log('Got API Data...')
          this.raw_data = this.raw_data.concat(res.data.data)
          // console.log('SET raw Data...')
          console.log(this.raw_data.length)
          this.filtered_data = this.getDataFilteredByDateRange(this.raw_data)
          console.log(this.filtered_data.length)
          this.map_load_text = `Loaded ${this.raw_data.length} of ${this.raw_data_count} trips`
          this.plotFilteredData()

          if (this.raw_data_count == this.raw_data.length) {
            this.map_load_text = 'Loaded...'
            this.vanishLoadText()
            this.showSlider = true
            return
          }

          await this.getTripMapData() // ;)
        })
        .catch((err) => {
          console.warn('Error Happened')
          console.log(err)
        })
    },
    asyncTimeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    async vanishLoadText() {
      await this.asyncTimeout(5000)
      this.map_load_text = ''
    },
    stylizeMap() {
      const styledMapType = new this.google.maps.StyledMapType(this.styles, {
        name: 'Trip Analysis Map',
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      })
      this.map.mapTypes.set('styled_map', styledMapType)
      this.map.setMapTypeId('styled_map')
    },
    initDraw() {
      var self = this // :p
      setTimeout(() => {
        if (!self.$refs.map) return
        self.$refs.map.$mapPromise.then(async (map) => {
          console.log('Hitting promise with', map)
          self.map = map

          //stylize map
          self.stylizeMap()
          self.addBikeLayer()
          // self.chartLoaded = true

          // populate data
          await self.getTripMapData(true)
        })
      }, 1000)
    },
    resetBoundForHeatMapData() {
      var bounds = new this.google.maps.LatLngBounds()
      for (const i of this.map_data) {
        bounds.extend(i)
      }
      this.map.setCenter(bounds.getCenter())
      this.map.fitBounds(bounds)
    },
    addBikeLayer() {
      if (this.map == null) return
      this.bikeLayer = new this.google.maps.BicyclingLayer()
      this.bikeLayer.setMap(this.map)
    },
    addHeatMapLayer() {
      if (this.map == null || this.map_data.length == 0) return

      if (this.heatmapLayer !== null) {
        this.heatmapLayer.setData(this.map_data)
        return
      }

      this.heatmapLayer = new this.google.maps.visualization.HeatmapLayer({
        data: this.map_data,
      })
      this.heatmapLayer.setMap(this.map)
      // this.toggleHeatmapLayerDissipation()
      this.toggleHeatmapOpacity()
      // console.log('Heatmap radius' + this.heatmapLayer.get('radius'))
      // this.heatmapLayer.set('radius', 50)
      // this.toggleHeatmapGradient()
    },
    addClusterLayer() {
      if (this.map == null || this.map_data.length == 0) return
      // let labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      let infoWindow = new this.google.maps.InfoWindow({
        content: '',
        disableAutoPan: true,
      })
      let markers = this.map_data.map((position, i) => {
        // let label = i//labels[i % labels.length]
        let marker = new this.google.maps.Marker({ position, i })

        marker.addListener('click', () => {
          infoWindow.setContent(position)
          infoWindow.open(this.map, marker)
        })

        return marker
      })

      // console.log(markers.length)
      let map = this.map
      // let clusterOpts = {
      //   maxZoom: 2,
      // }
      if (this.clusterLayer) this.clusterLayer.setMap(null)
      this.clusterLayer = new MarkerClusterer({ markers, map })
    },
    toggleHeatmap() {
      if (!this.heatmapLayer || !this.map) return
      this.heatmapLayer.setMap(this.heatmapLayer.getMap() ? null : this.map)
    },
    toggleHeatmapGradient() {
      if (!this.heatmapLayer || !this.map) return
      this.heatmapLayer.set(
        'gradient',
        this.heatmapLayer.get('gradient') ? null : this.gradient
      )
    },
    // centerMapToOrgLocation() {
    //     let org = this.$store.getters['auth/organizationInfo']
    //     let geocoder = new this.google.maps.Geocoder();
    //     geocoder.geocode({'address': `${org.city.name}`}, (results, status) => {
    //       if (status === 'OK') {
    //         this.map.setCenter(results[0].geometry.location);
    //       } else {
    //         console.warn('Geocode was not successful for the following reason : ' + status);
    //       }
    //     });
    // },
    toggleHeatmapOpacity() {
      if (!this.heatmapLayer || !this.map) return
      this.heatmapLayer.set(
        'opacity',
        this.heatmapLayer.get('opacity') ? null : 0.6
      )
    },
    toggleHeatmapLayerDissipation() {
      // Toggles whether heatmaps dissipate on zoom. When dissipating is false the radius of influence increases with zoom level to ensure that the color intensity is preserved at any given geographic location. Defaults to true.
      if (!this.heatmapLayer || !this.map) return
      this.heatmapLayer.set(
        'dissipating',
        this.heatmapLayer.get('dissipating') ? false : true
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.page-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(176, 199, 237, 1) 0%,
    rgba(203, 217, 240, 0.7)
  );
}
.main-title {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.section-title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
.section-title-faded {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9c9ca6;
}

.mc {
  max-width: 95%;
  margin: 0 auto;
}
.ph {
  min-height: 400px;
}
</style>
