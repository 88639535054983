<template>
  <div
    ref="filterContainerRef"
    class="relative flex items-center justify-between mb-3 bg-white rounded-t"
    style="
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.07);
      z-index: 50;
      height: 55px;
    "
  >
    <div class="flex gap-2 item-center">
      <button
        class="filter-base-dropdown-button app-form-reset"
        :class="{
          'bg-gray-100 text-gray-700 border-gray-200': !isShown,
          'bg-white text-oDark border-gray-200': isShown,
        }"
        aria-label="Filter menu"
        aria-haspopup="true"
        @click="onClickFilterMenu"
      >
        <i class="fas fa-filter text-oCharcoal" />

        <span class="font-semibold">Filters</span>

        <span
          :class="`filter-pills-count`"
          :style="
            `visibility: ${
              computeFilterablesAppliedCount > 0 ? 'visible' : 'hidden'
            }`
          "
        >
          {{ computeFilterablesAppliedCount }}
        </span>
      </button>

      <!-- NOTE: loop through the `computeVisiblePills` for responsive UI,
        but always bind with the real `filters` object to reference state -->
      <TDropdown
        v-for="filter in computeVisiblePills"
        :key="filter.key"
        variant="filterPill"
      >
        <template
          #trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
            isShown,
          }"
        >
          <button
            class="filter-base-dropdown-button app-form-reset"
            :class="{
              'bg-gray-100 text-gray-700 border-gray-200': !isShown,
              'bg-white text-oDark border-gray-200 shadow-md': isShown,
              'filter-is-applied': filter.isApplied,
            }"
            aria-label="Filter menu"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <span class="text-sm font-bold">{{ filter.title }}</span>

            <i class="fas fa-chevron-down" />
          </button>
        </template>

        <template v-slot:default="{ hide, blurHandler }">
          <div class="px-6 py-4 border-b">
            <!-- NOTE: use `filters` instead of `computeVisibleFilters`
              to reference the real state in model bindings-->
            <TCheckbox
              :model="definedFilters()[filter.key].all"
              :label="`All`"
              :name="`Vehicle Status`"
              :variant="`liveMapTitle`"
              :class="`ml-1`"
              wrapped
              @click="onFilterGroupSelectAllToggle({ group: filter.key })"
            />

            <div class="h-1" />

            <!-- NOTE: use `filters` instead of `computeVisibleFilters`
              to reference the real state in model bindings-->
            <div class="overflow-auto max-h-60 SB-FarhanShares">
              <div
                v-for="(modelValue, modelKey) in definedFilters()[filter.key]
                  .models"
                :key="filter.key + modelKey"
                :class="`filter-item`"
              >
                <TCheckbox
                  :model="modelValue"
                  :label="filter.labels[modelKey]"
                  :name="filter.labels[modelKey]"
                  :variant="`liveMap`"
                  wrapped
                  @change="onFilterChange(filter.key, modelKey, $event)"
                />

                <div class="filter-item-count">
                  {{
                    getFilterCounts[filter.key] &&
                    getFilterCounts[filter.key][modelKey] &&
                    getFilterCounts[filter.key][modelKey].count
                      ? getFilterCounts[filter.key][modelKey].count
                      : '0'
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-end gap-4 p-2 mt-4">
            <AppButton
              :text="`Clear`"
              :variant="`secondary`"
              :height="`32px`"
              @click="
                hide()
                onFilterGroupClear({ group: filter.key })
              "
              @blur="blurHandler"
            />

            <AppButton
              :text="
                `Show ${getFilterGroupCounts[filter.key].count} ${
                  filter.key == 'filterFleet' ? 'trips' : 'locations'
                }`
              "
              :variant="`green`"
              :height="`32px`"
              :disabled="shouldBeDisabled({ group: filter.key })"
              @click="
                hide()
                onFilterGroupApply({ group: filter.key })
              "
              @blur="blurHandler"
            />
          </div>
        </template>
      </TDropdown>

      <!-- filter-pills-last-item -->
      <TDropdown
        v-if="computeConcealedPillsCount > 0"
        variant="filterPill"
        class="hidden sm:block"
      >
        <template
          #trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
            isShown,
          }"
        >
          <button
            class="
              flex
              items-center
              justify-center
              px-1
              py-1
              text-sm text-black
              border border-gray-200
              rounded-full
              cursor-pointer
              app-form-reset
              hover:bg-white
            "
            style="height: 35px; width: 35px"
            :class="{
              'bg-gray-100 text-gray-700 border-gray-200': !isShown,
              'bg-white text-oDark border-gray-200 shadow-md': isShown,
            }"
            aria-label="Filter pill menu"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            {{ computeConcealedPillsCount }}+
          </button>
        </template>

        <div class="flex flex-col gap-2 px-2 py-2">
          <!-- NOTE: loop through the `computeVisiblePills` for responsive UI,
            but always bind with the real `filters` object to reference state -->
          <TDropdown
            v-for="filter in computeConcealedPills"
            :key="filter.key"
            variant="filterPill"
          >
            <template
              #trigger="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown,
              }"
            >
              <button
                class="filter-base-dropdown-button app-form-reset"
                :class="{
                  'bg-gray-100 text-gray-700 border-gray-200': !isShown,
                  'bg-white text-oDark border-gray-200 shadow-md': isShown,
                  'filter-is-applied': filter.isApplied,
                }"
                aria-label="Filter menu"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <span class="text-sm font-bold">{{ filter.title }}</span>

                <i class="fas fa-chevron-down" />
              </button>
            </template>

            <template v-slot:default="{ hide, blurHandler }">
              <div class="px-6 py-4 border-b">
                <!-- NOTE: use `filters` instead of `computeVisibleFilters`
              to reference the real state in model bindings-->
                <TCheckbox
                  :model="definedFilters()[filter.key].all"
                  :label="`All`"
                  :name="`Vehicle Status`"
                  :variant="`liveMapTitle`"
                  :class="`ml-1`"
                  wrapped
                  @click="onFilterGroupSelectAllToggle({ group: filter.key })"
                />

                <div class="h-1" />

                <!-- NOTE: use `filters` instead of `computeVisibleFilters`
              to reference the real state in model bindings-->
                <div class="overflow-auto max-h-60 SB-FarhanShares">
                  <div
                    v-for="(modelValue, modelKey) in definedFilters()[
                      filter.key
                    ].models"
                    :key="filter.key + modelKey"
                    :class="`filter-item`"
                  >
                    <TCheckbox
                      :model="modelValue"
                      :label="filter.labels[modelKey]"
                      :name="filter.labels[modelKey]"
                      :variant="`liveMap`"
                      wrapped
                      @change="
                        $store.commit('liveMap/onFilterChange', {
                          group: filter.key,
                          key: modelKey,
                          value: $event,
                        })
                      "
                    />

                    <div class="filter-item-count">
                      {{ getFilterCounts[filter.key][modelKey].count }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-end gap-4 p-2 mt-4">
                <AppButton
                  :text="`Clear`"
                  :variant="`secondary`"
                  :height="`32px`"
                  @click="
                    hide()
                    onFilterGroupClear({ group: filter.key })
                  "
                  @blur="blurHandler"
                />

                <AppButton
                  :text="`Show ${getFilterGroupCounts[filter.key].count} trips`"
                  :variant="`green`"
                  :height="`32px`"
                  :disabled="shouldBeDisabled({ group: filter.key })"
                  @click="
                    hide()
                    onFilterGroupApply({ group: filter.key })
                  "
                  @blur="blurHandler"
                />
              </div>
            </template>
          </TDropdown>
        </div>
      </TDropdown>
    </div>

    <DashboardDatePicker
      class="prediction"
      ref="heatmap_date_picker"
      @apply-date="onChangeDate($event, 'maps')"
      @cancel-date="handleCancelDate($event, 'maps')"
    />
  </div>
</template>

<script>
import DashboardDatePicker from '@/components/picker/date-range/DashboardDatePicker'
export default {
  name: 'LiveMapFilterPills',
  props: {
    count: {
      type: Object,
      required: true,
    },
    fleetData: {
      type: Array,
      required: true,
    },
  },
  components: {
    DashboardDatePicker,
  },

  data() {
    return {
      isShown: false,
      fleetCounts: {},
      filterContainerWidth: 0,
      isRealtime: false, // todo: move to state
      filterFleet: {
        key: 'filterFleet',
        title: 'Fleet',

        isApplied: false,
        lastAppliedAt: null,

        all: false,
        models: {}, // ds: {'id': boolean}
        labels: {}, // ds: {'id': 'name'}
      },
      filterLocationPoint: {
        key: 'filterLocationPoint',
        title: 'Location Point',

        isApplied: false,
        lastAppliedAt: null,

        all: false,
        models: {
          pickup_points: false,
          dropoff_points: false,
        },
        labels: {
          pickup_points: 'Pick up Points',
          dropoff_points: 'Drop off Points',
        },
      },
      filter_date_range: {
        start: null,
        end: null,
      },
      reqInit: false,
      reqBusy: false,
      filteredFleetCount: 0,
    }
  },
  computed: {
    getFilterFleetSelected() {
      var selected_fleets = []
      for (const k in this.filterFleet.models) {
        if (this.filterFleet.models[k] === true) {
          selected_fleets.push(k)
        }
      }
      return selected_fleets
    },
    getFilterSlotMaxWidth() {
      const leftSide = 140 // all filter button
      const rightSide = 170 // realtime toggle button
      const width = this.filterContainerWidth - rightSide - leftSide

      return width > 0 ? width : 0
    },

    computeVisiblePillsMaxCount() {
      // 45px allowance for the last element
      const avialableWidth = this.getFilterSlotMaxWidth - 45
      // based on use case / an wild guess (I can't help more on it as of now)
      const averageWidthPerPill = 125
      // least possible value for making sure UI won't be broken
      return Math.floor(avialableWidth / averageWidthPerPill)
    },
    computeVisiblePills() {
      let n = 1
      let pills = {}
      for (const k in this.definedFilters()) {
        if (n > this.computeVisiblePillsMaxCount) {
          break
        }
        pills[k] = this.definedFilters()[k]
        n++
      }

      // console.log('visiblePills', Object.keys(pills).length)

      return pills
    },

    computeConcealedPills() {
      let n = 1
      const concealedPills = {}

      for (const k in this.definedFilters()) {
        if (n <= this.computeVisiblePillsMaxCount) {
          n++
          continue
        }
        concealedPills[k] = this.definedFilters()[k]
      }
      return concealedPills
    },

    computeConcealedPillsCount() {
      return Object.keys(this.computeConcealedPills).length
    },

    computeFilterablesAppliedCount() {
      let count = 0
      if (this.filterFleet.isApplied) count += 1
      if (this.filterLocationPoint.isApplied) count += 1
      // loop through all filter groups -> look for true values, add count
      return count
    },

    getFilterCounts() {
      // ds: key:{count}
      const filterFleet = {}
      for (const k in this.count.fleets) {
        console.log('fleet count key ', k)
        filterFleet[k] = { count: this.count.fleets[k] }
      }
      // console.log('=======================')
      // console.log(filterFleet)
      // console.log('=======================')

      // ds: key:{count, percentage}
      const c = {
        filterFleet,

        filterLocationPoint: {
          pickup_points: { count: this.count.pick_up },
          dropoff_points: { count: this.count.drop_off },
        },
      }
      return c
    },
    getFilterLocationPointCount() {
      // const counts = 0 //this.getFilterCounts.filterLocationPoint
      const { pickup_points, dropoff_points } = this.filterLocationPoint.models

      if (pickup_points && dropoff_points)
        return this.count.pick_up + this.count.drop_off
      else if (pickup_points) return this.count.pick_up
      else if (dropoff_points) return this.count.drop_off
      else return 0
    },
    getFilterGroupCounts() {
      return {
        filterFleet: { count: this.filteredFleetCount },
        filterLocationPoint: { count: this.getFilterLocationPointCount },
      }
    },
  },
  watch: {
    fleetData: {
      deep: true,
      handler(updated) {
        console.log('FLEETS LOADED', this.fleetData.length, updated.length)
        this.fleetData.forEach((data) => {
          // console.log(`${data.name} -> ${data.count}`)
          this.filterFleet.labels[data.id] = data.name
          this.filterFleet.models[data.id] = false
        })
        console.log(
          'FLEET MDL',
          this.filterFleet.models,
          this.filterFleet.labels
        )
      },
    },
  },
  created() {
    window.removeEventListener('resize', this.getFilterContainerWidth)
    window.addEventListener('resize', this.getFilterContainerWidth)
    this.getFilterContainerWidth()
  },
  mounted() {
    this.$refs.heatmap_date_picker.setEasyDatePickRange('last-28days')
    this.filter_date_range.start = this.$refs.heatmap_date_picker.selectedRange.start
    this.filter_date_range.end = this.$refs.heatmap_date_picker.selectedRange.end
  },
  methods: {
    definedFilters() {
      let filterFleet = this.filterFleet
      let filterLocationPoint = this.filterLocationPoint
      return { filterFleet, filterLocationPoint }
    },
    async onChangeDate(event, type) {
      console.log({ event, type })
      this.filter_date_range = event
      this.$emit('filterHeatmapData', {})
    },
    async handleCancelDate(event, type) {
      console.log({ event, type })
    },
    onClickFilterMenu() {
      // const currentState = !this.isShown
      // this.$emit('filter-menu', { shown: currentState })
      this.isShown = !this.isShown
      // this.$store.commit('liveMap/drawerRightVisibilityToggle')
    },
    onFilterChange(filterKey, modelKey, event) {
      console.log(`${filterKey} ${modelKey} ${event}`)
      // if (filterKey == 'filterLocationPoint') {
      //   if (modelKey == 'pickup_points') {
      //   }
      // }
      if (filterKey == 'filterLocationPoint') {
        this.filterLocationPoint.models[modelKey] = event
      } else if (filterKey == 'filterFleet') {
        this.filterFleet.models[modelKey] = event
      }

      // let mf = this.map_filters
      if (event == false) {
        if (filterKey == 'filterLocationPoint') {
          this.filterLocationPoint.all = false

          if (
            !this.filterLocationPoint.models.pickup_points &&
            !this.filterLocationPoint.models.dropoff_points
          ) {
            this.filterLocationPoint.isApplied = false
          }
        } else if (filterKey == 'filterFleet') {
          this.filterFleet.all = false
          if (
            Object.values(this.filterFleet.models).every((v) => v === false)
          ) {
            this.filterFleet.isApplied = false
          }
        }
        this.$emit('filterHeatmapData', {})
      } else {
        if (filterKey == 'filterLocationPoint') {
          if (
            this.filterLocationPoint.models.pickup_points &&
            this.filterLocationPoint.models.dropoff_points
          ) {
            this.filterLocationPoint.all = true
          }
        } else if (filterKey == 'filterFleet') {
          if (Object.values(this.filterFleet.models).every((v) => v === true)) {
            this.filterFleet.all = false
          }
        }
      }

      if (filterKey == 'filterFleet') this.updateFilteredFleetCount()
    },
    // eslint-disable-next-line no-unused-vars
    onFilterGroupApply({ group }) {
      // append or push or update the filtered result to the list view & map marker
      // this.$store.dispatch('liveMap/onFilterApply', { group, isApplied: true })
      console.log('Group applied ' + group)
      if (group == 'filterLocationPoint') {
        this.filterLocationPoint.isApplied = true
      }
      if (group == 'filterFleet') {
        this.filterFleet.isApplied = true
      }
      this.$emit('filterHeatmapData', {})
    },

    onFilterGroupClear({ group }) {
      if (group == 'filterLocationPoint') {
        this.filterLocationPoint.all = false
        this.filterLocationPoint.models.pickup_points = false
        this.filterLocationPoint.models.dropoff_points = false
        this.filterLocationPoint.isApplied = false
      } else if (group == 'filterFleet') {
        this.filterFleet.all = false
        for (const k in this.filterFleet.models) {
          this.filterFleet.models[k] = false
        }
        this.filterFleet.isApplied = false
        this.updateFilteredFleetCount()
      }
      this.$emit('filterHeatmapData', {})
    },

    onFilterGroupSelectAllToggle({ group }) {
      // todo: group validation -> on err -> console.log -> through exception
      // this.$store.commit('liveMap/onFilterChange', {
      //   group,
      //   key: 'all',
      // })
      console.log('selectAll')
      if (group == 'filterLocationPoint') {
        this.filterLocationPoint.all = !this.filterLocationPoint.all
        if (this.filterLocationPoint.all) {
          this.filterLocationPoint.models.pickup_points = true
          this.filterLocationPoint.models.dropoff_points = true
          this.filterLocationPoint.isApplied = true
        } else {
          this.filterLocationPoint.models.pickup_points = false
          this.filterLocationPoint.models.dropoff_points = false
          this.filterLocationPoint.isApplied = false
          this.$emit('filterHeatmapData', {})
        }
      } else if (group == 'filterFleet') {
        this.filterFleet.all = !this.filterFleet.all
        if (this.filterFleet.all) {
          for (const k in this.filterFleet.models) {
            this.filterFleet.models[k] = true
          }
          this.filterFleet.isApplied = true
        } else {
          for (const k in this.filterFleet.models) {
            this.filterFleet.models[k] = false
          }
          this.filterFleet.isApplied = false
          this.$emit('filterHeatmapData', {})
        }
        this.updateFilteredFleetCount()
      }
      // this.$emit('filterHeatmapData', {})
    },
    updateFilteredFleetCount() {
      let c = 0
      for (const k in this.filterFleet.models) {
        if (this.filterFleet.models[k] === true) c += this.count.fleets[k]
      }
      this.filteredFleetCount = c
    },
    shouldBeDisabled({ group }) {
      // todo: validate filter group
      const count = this.getFilterGroupCounts[group]?.count || 0

      console.log({ shouldBeDisabled: count })
      return count > 0 ? false : true
    },

    getFilterContainerWidth() {
      this.$nextTick(() => {
        this.filterContainerWidth =
          this.$refs?.filterContainerRef?.clientWidth || 0
        console.log({ tlsw: this.filterContainerWidth })
      })
    },
  },
}
</script>

<style>
.prediction .dropdown-button-size {
  height: 35px !important;
  box-shadow: none !important;
  @apply bg-gray-100;
}
</style>

<style lang="scss" scoped>
.filter-item {
  @apply flex items-center justify-between;

  .filter-item-count {
    @apply text-xs text-gray-500 mb-1;
  }
}
</style>

<style lang="scss" scoped>
.filterGroupsContainer {
  min-width: 241px;
}
@media screen and (min-width: 640px) {
  .filterGroupsContainer {
    width: auto;
  }
}
.filter-pills-count {
  height: 17px;
  width: 17px;
  font-size: 13px;
  line-height: 17px;
  @apply inline-block text-center text-white bg-blue-700 rounded-full;
}

.filter-base-dropdown-button {
  @apply flex items-center gap-3 px-4 py-1.5 border rounded-full hover:bg-white text-14px;
}

.min-w-filter-pill {
  min-width: 12rem;
  max-width: 15rem;
}

.fs-table-filter-dropdown-menu {
  min-width: 181px;
  width: auto;
  max-width: calc(100vw - 50px);
}

@media screen and (min-width: 640px) {
  .fs-table-filter-dropdown-menu {
    min-width: 330px;
    width: 50vw;
  }
}
.filter-is-applied {
  color: #fff !important;
  background-color: rgba(59, 130, 246, 1) !important;
}
</style>
